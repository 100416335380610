<template>
  <transition
    name="fade"
    @before-enter="onBeforeEnter"
    @enter="onEnter"
    @after-enter="onAfterEnter"
    @enter-cancelled="onEnterCancelled"
    @before-leave="onBeforeLeave"
    @leave="onLeave"
    @after-leave="onAfterLeave"
    @leave-cancelled="onLeaveCancelled"
  >
    <div v-show="show" class="fixed inset-0 bg-mask flex items-center justify-center z-modal" @pointerdown="clickMask">
      <div v-if="props.card" class="bg-white md:w-440px md:rounded" :class="cardClass" v-bind="$attrs" @pointerdown.stop>
        <div class="h-16 pt-4 pb-2 pl-5 pr-3 flex justify-end items-center" :class="props.title ? 'justify-between' : 'justify-end'">
          <p v-if="title" class="text-lg">{{ props.title }}</p>
          <nd-button v-if="closable" type="icon" icon="cancel" class="w-10 h-10 px-2" @click="closeModal"> </nd-button>
        </div>
        <slot>I am modal</slot>
        <div v-if="isDialog">
          <slot name="dialog">
            <div class="py-6 px-5 flex justify-end gap-2">
              <nd-button type="tertiary" @click="emit('cancel')">
                {{ props.cancelText }}
              </nd-button>
              <nd-button type="primary" @click="emit('confirm')">
                {{ props.confirmText }}
              </nd-button>
            </div>
          </slot>
        </div>
      </div>
      <slot v-else></slot>
    </div>
  </transition>
</template>

<script setup lang="ts">
type Emits = {
  (event: 'cancel'): void
  (event: 'confirm'): void
  (event: 'before-enter'): void
  (event: 'enter'): void
  (event: 'after-enter'): void
  (event: 'enter-cancelled'): void
  (event: 'before-leave'): void
  (event: 'leave'): void
  (event: 'after-leave'): void
  (event: 'leave-cancelled'): void
}

const props = withDefaults(
  defineProps<{
    card?: boolean
    cardClass?: string
    maskClosable?: boolean
    closable?: boolean
    title?: string
    isDialog?: boolean
    cancelText?: string
    confirmText?: string
  }>(),
  {
    card: true,
    cardClass: '',
    maskClosable: true,
    closable: true,
    isDialog: false,
    title: '',
    cancelText: () => useI18n().t('all_cancel'),
    confirmText: () => useI18n().t('all_confirm'),
  }
)

const emit = defineEmits<Emits>()

const show = defineModel('show', {
  type: Boolean,
  default: true,
})

const closeModal = () => {
  show.value = false
}

const clickMask = () => {
  if (props.maskClosable) {
    closeModal()
  }
}

const onBeforeEnter = () => emit('before-enter')
const onEnter = () => emit('enter')
const onAfterEnter = () => emit('after-enter')
const onEnterCancelled = () => emit('enter-cancelled')
const onBeforeLeave = () => emit('before-leave')
const onLeave = () => emit('leave')
const onAfterLeave = () => emit('after-leave')
const onLeaveCancelled = () => emit('leave-cancelled')
</script>

<style scoped>
/* unocss bg-black not work，用 bg-mask 取代 */
.bg-mask {
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
